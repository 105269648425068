/* ::-webkit-scrollbar {
  display: none;
} */

.waitlist-we-get-it {
  margin-top: 20px;
}

.waitlist-screen-with-learn-more {
  /* overflow-y: auto; */
  /* overscroll-behavior: contain; */
  /* scroll-snap-type: y mandatory; */
  /* height: 100vh; */
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.waitlist-screen-with-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  scroll-snap-align: center;
}

.waitlist-screen {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 10vw 0 10vw;
  background-color: var(--primaryYellow);
  overflow-y: auto;
  overflow-x: hidden;
  filter: blur(0);
  transition: all 0.4s ease;
}

.waitlist__left-content,
.waitlist__right-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  height: 100%;
  max-width: 50vw;
  padding: 0 10px;
}

.waitlist__right-content {
  align-items: center;
  justify-content: center;
}

.waitlist__logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.waitlist__logo {
  width: 58px;
  height: 58px;
  margin-right: 4px;
  margin-bottom: -2px;
  margin-left: -8px;
}

.waitlist__logo-text {
  font-family: 'CircularStd', sans-serif;
  font-weight: 900;
  font-size: 3rem;
  letter-spacing: -0.05rem;
  color: var(--blackText);
}

.waitlist-title {
  font-family: 'CircularStd', sans-serif;
  font-weight: 700;
  font-size: 2.75rem;
  color: var(--blackText);  
  margin-bottom: 0px;
  width: 100%;
}

.waitlist-description {
  font-weight: 400;
  font-size: 1.5rem;
}

.waitlist__right-content__form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
}

.waitlist-screen form {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.waitlist__form-title {
  font-family: 'CircularStd', sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--blackText);
  width: 100%;
  margin-bottom: 16px;
}

.waitlist__email-input {
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 5px;
  background: var(--whiteBackground);
  color: var(--blackText);
  font-size: 18px;
  transition: all 0.2s ease;
  margin-right: 16px;
  width: 250px;
}

.waitlist__submit-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background: var(--blackText);
  color: var(--whiteBackground);
  font-family: 'CircularStd', sans-serif;
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.waitlist__submit-btn:hover {
  transform: scale(1.05);
}

.waitlist__right-img {
  width: 85%;
  padding: 0 20px;
}

.waitlist__learn-more-desc {
  color: var(--blackText);
  white-space: nowrap;
  text-decoration: underline;
  cursor: pointer;
  /* font-style: italic; */
}

.waitlist__learn-more__arrow {
  width: 18px;
  height: 18px;
  margin-left: 4px;
  align-self: center;
  justify-self: center;
  text-align: center;
  vertical-align: middle;
  margin-top: -2px;
  transition: all 0.15s ease-in-out;
  opacity: 0.9;
}

.waitlist__learn-more-desc:hover>.waitlist__learn-more__arrow {
  margin-left: 8px;
}


.learn-more-bottom-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background: rgb(253, 242, 189, 0.5); */
  background: #FBE370;
  width: 100vw;
  height: 42px;
  cursor: pointer;
  /* z-index: 1; */
}

@keyframes scroll_2 {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  25% {
    opacity: 1;
  }

  75% {
    transform: translateY(9px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 0;
  }
}

.scroll-icon__dot {
  display: block;
  position: absolute;
  left: 50%;
  background: var(--blackText);
  height: 5px;
  width: 5px;
  top: 2px;
  margin-left: -2px;
  border-radius: 100px;
  transform-origin: top center;
  backface-visibility: hidden;
  animation: scroll_2 1.5s ease-out infinite;
}

.scroll-icon {
  display: block;
  position: relative;
  height: 20px;
  width: 8px;
  border: 2px solid var(--blackText);
  border-radius: 100px;
  /* opacity: 0.5; */
}


.learn-more-bottom-text {
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--blackText);
  margin-right: 4px;
  margin-left: 6px;
  z-index: 1;
}

@keyframes bounce {
  0% {
    transform: rotate(90deg) translateX(-1.5px);
  }

  25% {
    transform: rotate(90deg) translateX(1px);
  }

  50% {
    transform: rotate(90deg) translateX(3px);
  }

  75% {
    transform: rotate(90deg) translateX(1px);
  }

  100% {
    transform: rotate(90deg) translateX(-1.5px);
  }
}

.learn-more-bottom-arrow {
  width: 19px;
  height: 19px;
  margin-left: 4px;
  margin-top: -4px;
  align-self: center;
  justify-self: center;
  text-align: center;
  vertical-align: middle;
  transition: all 0.15s ease;
  opacity: 0.9;
  transform: rotate(90deg);
  animation: bounce 1.75s infinite linear;
}

.learn-more-bottom-container:hover .learn-more-bottom-arrow {
  transform: rotate(90deg) translateX(3px);
}

.waitlist__logo-container.top {
  display: none;
  margin-top: 20px;
}

.waitlist__right-content.top {
  display: none;
}

/* .waitlist__captcha-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  transition: all 1s ease;
  overflow: hidden;
}

.waitlist__captcha-container.open {
  max-height: 300px;
}

.waitlist__captcha-container.closed {
  max-height: 0px;
} */

@media only screen and (max-width: 1180px) {
  .waitlist-screen {
    padding: 0 5vw 0 10vw;
  }
}

@media only screen and (max-width: 1120px) {
  .waitlist__logo-container {
    margin-bottom: 16px;
  }

  .waitlist__logo {
    width: 48px;
    height: 48px;
    margin-right: 4px;
    margin-bottom: -2px;
    margin-left: -8px;
  }

  .waitlist__logo-text {
    font-size: 2.5rem;
    font-weight: 700;
  }

  .waitlist-title {
    font-size: 2.5rem;
  }

  .waitlist__right-img {
    width: 100%;
  }

  .waitlist-description {
    font-size: 1.1rem;
  }

  .waitlist__form-title {
    font-size: 1.25rem;
  }

  .waitlist__email-input {
    width: 200px;
    font-size: 16px;
  }

  .waitlist__submit-btn {
    font-size: 16px;
  }

}

@media screen and (max-width: 882px) {

  .waitlist-screen-with-bottom {
    height: 100vh;
    /* height: min-content; */
  }

  .waitlist-screen {
    justify-content: flex-start;
    flex-direction: column;
    padding: 0 15vw;
    /* max-height: 100vh; */
  }

  .waitlist__left-content,
  .waitlist__right-content {
    max-width: 100%;
    padding: 0 10px;
  }

  .waitlist__left-content {
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
  }

  .waitlist__right-content.top {
    display: flex;
    width: 100%;
  }

  .waitlist__right-content.right {
    display: none;
  }

  .waitlist__logo-container.top {
    display: flex;
  }

  .waitlist__logo-container.left {
    display: none;
  }

  .waitlist__logo-container {
    margin-bottom: 16px;
  }

  .waitlist__logo {
    width: 48px;
    height: 48px;
    margin-right: 4px;
    margin-bottom: -2px;
    margin-left: -8px;
  }

  .waitlist__logo-text {
    font-size: 2.5rem;
    font-weight: 700;
  }

  .waitlist-title {
    font-size: 2.3rem;
    text-align: center;
  }

  .waitlist__right-img {
    width: 100%;
    max-width: 350px;
    margin-bottom: 10px;
  }

  .waitlist-description {
    font-size: 1.1rem;
    text-align: center;
  }

  .waitlist__right-content__form {
    align-items: center;
    width: 100%;
  }

  .waitlist__form-title {
    font-size: 1.25rem;
    text-align: center;
  }

  .waitlist__email-input {
    width: 200px;
    font-size: 16px;
  }

  .waitlist__submit-btn {
    font-size: 16px;
  }
}

@media screen and (max-width: 650px) {
  .waitlist-screen {
    padding: 0 20px;
    justify-content: flex-start;
  }

  .waitlist__right-content,
  .waitlist__left-content {
    height: fit-content !important;
    flex: 0 1 !important;
    /* margin-top: 12px; */
    /* margin-bottom: -20px; */
  }

  .waitlist__right-img {
    width: 100%;
    max-width: 250px;
    margin-bottom: 10px;
  }

  .waitlist-title {
    font-size: 1.75rem;
  }

  .waitlist-description {
    font-size: 1rem;
    line-height: 1.35rem;
  }

  .waitlist__right-content__form>form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .waitlist__email-input {
    margin: 0;
    width: calc(100% - 32px - 8px);
    margin-bottom: 10px;
    padding: 12px 16px;
  }

  .waitlist__submit-btn {
    margin: 0;
    width: calc(100% - 8px);
    padding: 12px 16px;
  }

  .waitlist__logo-container.top>.waitlist__logo-text.top {
    font-size: 2.25rem;
  }

  .waitlist__logo-container.top>.waitlist__logo.top {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-height: 755px) and (max-width: 882px) {
  .waitlist-screen-with-bottom {
    height: fit-content;
  }
}

@media screen and (max-width: 455px) {
  .learn-more-bottom-container {
    height: fit-content;
    padding-bottom: 8px;
  }
}