.modal-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 99;
  transition: all 0.25s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
}

.modal-background.active {
  opacity: 1;
  pointer-events: all;
}

.modal-background.navbar {
  opacity: 0;
  z-index: 40;
}

.modal-content {
  background-color: var(--whiteBackground);
  padding: 20px 25px 25px 25px;
  border-radius: 12px;
  position: relative;
  min-width: 250px;
  max-width: 400px;
  /* width: 40vw; */
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  z-index: 10;
  opacity: 1;
  filter: blur(0);
  transform: translateY(-100%);
  transition: all 0.4s ease;
}

.modal-content-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.modal-background.active>.modal-content {
  transform: translateY(0);
}

.modal-content.loading {
  opacity: 0;
  filter: blur(15px);
}

.modal-close-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 6px;
  fill: var(--textMain);
  width: 36px;
  height: 36px;
  cursor: pointer;
  transition: all 0.25s ease;
  pointer-events: all;
}

.modal-close-icon:hover {
  transform: scale(1.1);
}

.modal-content h1 {
  margin-bottom: 24px;
  font-size: 24px;
  color: var(--textMain);
}