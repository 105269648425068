.infinite-carousel-scroller {
  max-width: 100%;
  overflow: hidden;
  -webkit-mask: linear-gradient(90deg,
      transparent,
      white 10%,
      white 90%,
      transparent);
  mask: linear-gradient(90deg,
      transparent,
      white 10%,
      white 90%,
      transparent);
}

.scroller__inner {
  display: flex;
  gap: 1rem;
  padding-block: 1rem;
  animation: scroll 24s linear infinite;
  width: max-content;
}

.scroller__inner>li {
  list-style: none;
}

.ic-image {
  width: 200px;
  height: auto;
  object-fit: contain;
  object-position: center;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}