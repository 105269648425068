.wave-footer {
  fill: var(--lighterYellow);
}

.wave-footer.down {
  transform: rotate(180deg);
}

.wave-footer-path1 {
  fill: #FBE370;
}

.wave-footer-path2 {
  fill: var(--lighterYellow);
  opacity: 0.7;
}

.wave-footer-path3 {
  /* fill: var(--lighterYellow); */
  fill: #FBE370;
  /* opacity: 0.5; */
}