.learn-more-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: var(--primaryYellow);
  position: relative;
  /* padding: 0 10vw; */
  /* scroll-snap-align: center; */
}

.learn-more-screen-title {
  font-family: 'CircularStd', sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: var(--blackText);
  width: 100%;
  text-align: center;
  margin: 0 10vw;
  margin-top: calc(9vw + 35px);
  white-space: normal;
}


.lms-mockup-masonry-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: auto;
  margin: 0 10vw;
  margin-top: 50px;
  z-index: 3;
  flex-wrap: wrap;
}

.lms-mockup-container-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 200px;
  height: auto;
  /* margin: 0 10px; */
}

.lms-mockup-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
}

.lms-mockup-image.margin {
  margin-top: 65px;
}

.learn-more-screen-bottom-spacer {
  width: 100%;
  height: 50px;
}

.learn-more-screen-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 20vw);
  margin-top: 50px;
}

@media only screen and (max-width: 1252px) {

  .learn-more-screen-title {
    width: calc(100% - 18vw);
  }

  .lms-mockup-masonry-container {
    width: calc(100% - 10vw);
  }

  .lms-mockup-container-item {
    width: 175px;
  }
}

@media screen and (max-width: 975px) {
  .lms-mockup-masonry-container {
    width: 575px;
  }
}